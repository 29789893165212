import { useMergeLink } from '@mergeapi/react-merge-link';
import { useQueryClient } from 'react-query';
import { useCallback, useEffect, useState } from 'react';
import { useProgram } from 'contexts/program';
import { getMergeIntegrationsQueryKey } from 'hooks/merge-integration';
import { SupportedIntegration } from 'models/MergeIntegration/integrations';
import {
  completeMergeLinkSession,
  createMergeLinkSession,
} from 'services/api-merge-integration';

type HookResult = {
  isLoading: boolean;
  initiateLink: () => void;
};

export function useMergeLinkConfiguration(
  type: SupportedIntegration
): HookResult {
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();

  const [integrationId, setIntegrationId] = useState<string>();
  const [linkToken, setLinkToken] = useState<string>();
  const [isWaitingForLink, setIsWaitingForLink] = useState(false);
  const [isFinishingSetup, setIsFinishingSetup] = useState(false);

  const onMergeLinkSuccess = useCallback(
    async (publicToken: string) => {
      if (!integrationId) return;

      setIsFinishingSetup(true);

      try {
        await completeMergeLinkSession(programId, {
          integrationId,
          publicToken,
        });

        await queryClient.invalidateQueries(
          getMergeIntegrationsQueryKey(programId, type)
        );
      } finally {
        setLinkToken(undefined);
        setIsFinishingSetup(false);
      }
    },
    [integrationId, programId, queryClient, type]
  );

  const onMergeLinkExit = useCallback(() => {
    setLinkToken(undefined);
    setIsWaitingForLink(false);
  }, []);

  const { open: openMergeLink, isReady: isMergeLinkReady } = useMergeLink({
    linkToken,
    onSuccess: onMergeLinkSuccess,
    onExit: onMergeLinkExit,
  });

  const initiateLink = useCallback(async () => {
    setIsWaitingForLink(true);

    try {
      const session = await createMergeLinkSession(programId, type);
      setIntegrationId(session.id);
      setLinkToken(session.linkToken);
    } catch (err) {
      setIsWaitingForLink(false);
    }
  }, [programId, type]);

  useEffect(() => {
    if (!isWaitingForLink || !linkToken || !isMergeLinkReady) return;

    openMergeLink();
    setIsWaitingForLink(false);
  }, [isMergeLinkReady, openMergeLink, isWaitingForLink, linkToken]);

  const isLoading = isWaitingForLink || isFinishingSetup;

  return {
    isLoading,
    initiateLink,
  };
}
