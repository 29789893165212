import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from 'react-query';
import { useProgram } from 'contexts/program';
import {
  fetchComments,
  fetchReplies,
  deleteCommentFlags,
  deleteComment,
  CommentsCollectionData,
  CommentsData,
  Comment,
} from 'services/api-content';
import { flaggedCountQueryKey } from 'hooks/posts-metadata';
import { InfiniteQueryResponse, QueryError } from 'hooks/common';

type MutationResponse<T> = {
  mutate: (data: T) => void;
  isLoading: boolean;
  errorMessage?: string;
};

/**
 * Base key for all comments-related queries
 */
export const COMMENTS_BASE_KEY = 'comments' as const;

/**
 * Creates a cache key for comments queries. The key structure is:
 * - Root comments: ['comments', programId, contentId, filters]
 * - Replies: ['comments', programId, contentId, filters, 'replies', parentId]
 *
 * Cache invalidation examples:
 * - All comments: invalidateQueries(['comments'])
 * - All comments for content: invalidateQueries(['comments', programId, contentId])
 * - Specific reply thread: invalidateQueries(['comments', programId, contentId, [], 'replies', parentId])
 *
 * Note: Invalidating a reply thread will NOT invalidate the root comments query.
 * To invalidate both, use the content-level invalidation.
 */
export const getCommentsKey = (
  programId: number,
  contentId: number,
  options?: {
    /** Filter values to apply */
    filters?: string[];
    /** Parent comment ID for replies */
    parentId?: string;
  }
): (number | string | string[])[] => {
  const base = [COMMENTS_BASE_KEY, programId, contentId];
  const filters = options?.filters ?? [];

  if (options?.parentId) {
    return [...base, filters, 'replies', options.parentId];
  }

  return [...base, filters];
};

type UseCommentsProps = {
  /** The ID of the program */
  programId: number;
  /** The ID of the content */
  contentId: number;
  /** Optional array of filter values */
  filters?: string[];
  /** Number of items per page */
  pageSize?: number;
  /** Enable/disable the query */
  enabled?: boolean;
  /** Refetch interval in milliseconds */
  refetchInterval?: number | false;
};

type UseCommentRepliesProps = {
  /** The ID of the program */
  programId: number;
  /** The ID of the content */
  contentId: number;
  /** The ID of the parent comment */
  commentId: string;
  /** Optional array of filter values */
  filters?: string[];
  /** Number of items per page */
  pageSize?: number;
  /** Enable/disable the query */
  enabled?: boolean;
  /** Refetch interval in milliseconds */
  refetchInterval?: number | false;
};

const updateFlagsClearedCache = (
  comment: Comment,
  queryClient: QueryClient
) => {
  const { programId, contentId } = comment;
  const queryKey = getCommentsKey(programId, contentId, {
    parentId: comment.replyToId ?? undefined,
  });

  // Update the comments cache
  queryClient.setQueryData<{ pages: CommentsCollectionData[] }>(
    queryKey,
    (oldData) => {
      if (!oldData?.pages) return { pages: [] };

      // Update the comment's isReported flag
      const updatedPages = oldData.pages.map((page) => ({
        ...page,
        data: page.data.map((c) => {
          if (c.id === comment.id) {
            return {
              ...c,
              attributes: {
                ...c.attributes,
                isReported: false,
              },
            };
          }
          return c;
        }),
      }));

      return {
        pages: updatedPages,
      };
    }
  );

  // If this is a reply, update the parent's reported reply count
  if (comment.replyToId) {
    // If the parent comment is a root comment, update the root comments query
    if (!comment.parentComment?.replyToId) {
      const rootQueryKey = getCommentsKey(programId, contentId);
      queryClient.setQueryData<{ pages: CommentsCollectionData[] }>(
        rootQueryKey,
        (oldData) => {
          if (!oldData?.pages) return { pages: [] };

          const updatedPages = oldData.pages.map((page) => ({
            ...page,
            data: page.data.map((c) => {
              if (c.id === comment.replyToId) {
                return {
                  ...c,
                  attributes: {
                    ...c.attributes,
                    replyReportedCount: Math.max(
                      0,
                      (c.attributes.replyReportedCount ?? 0) - 1
                    ),
                  },
                };
              }
              return c;
            }),
          }));

          return {
            pages: updatedPages,
          };
        }
      );
    } else {
      // If the parent comment is a reply, update its parent's replies query
      const parentRepliesKey = getCommentsKey(programId, contentId, {
        parentId: comment.parentComment.replyToId,
      });
      queryClient.setQueryData<{ pages: CommentsCollectionData[] }>(
        parentRepliesKey,
        (oldData) => {
          if (!oldData?.pages) return { pages: [] };

          const updatedPages = oldData.pages.map((page) => ({
            ...page,
            data: page.data.map((c) => {
              if (c.id === comment.replyToId) {
                return {
                  ...c,
                  attributes: {
                    ...c.attributes,
                    replyReportedCount: Math.max(
                      0,
                      (c.attributes.replyReportedCount ?? 0) - 1
                    ),
                  },
                };
              }
              return c;
            }),
          }));

          return {
            pages: updatedPages,
          };
        }
      );
    }
  }
};

/**
 * Hook for clearing flags from a comment
 */
export const useClearFlagsMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: QueryError) => void;
} = {}): MutationResponse<Comment> => {
  const queryClient = useQueryClient();
  const { id: programId } = useProgram();
  const { mutate, isLoading, error } = useMutation(
    ['comments/defer_flags'],
    deleteCommentFlags,
    {
      onSuccess: (_, variables) => {
        // Update the cache
        updateFlagsClearedCache(variables, queryClient);
        // Invalidate flagged count
        queryClient.invalidateQueries(flaggedCountQueryKey(programId));
        queryClient.invalidateQueries(
          getCommentsKey(programId, variables.contentId)
        );
        onSuccess?.();
      },
      onError,
    }
  );

  return { mutate, isLoading, errorMessage: error?.message };
};

const updateCommentCache = (comment: Comment, queryClient: QueryClient) => {
  const { programId, contentId } = comment;
  const queryKey = getCommentsKey(programId, contentId, {
    parentId: comment.replyToId ?? undefined,
  });

  // Update the comments cache
  queryClient.setQueryData<{ pages: CommentsCollectionData[] }>(
    queryKey,
    (oldData) => {
      if (!oldData?.pages) return { pages: [] };

      // Mark the comment as deleted
      const updatedPages = oldData.pages.map((page) => ({
        ...page,
        data: page.data.map((c) => {
          if (c.id === comment.id) {
            return {
              ...c,
              attributes: {
                ...c.attributes,
                deletedAt: new Date().toISOString(),
              },
            };
          }
          return c;
        }),
      }));

      return {
        pages: updatedPages,
      };
    }
  );

  // If this is a reply, update the parent's reply count
  if (comment.replyToId) {
    // If the parent comment is a root comment, update the root comments query
    if (!comment.parentComment?.replyToId) {
      const rootQueryKey = getCommentsKey(programId, contentId);
      queryClient.setQueryData<{ pages: CommentsCollectionData[] }>(
        rootQueryKey,
        (oldData) => {
          if (!oldData?.pages) return { pages: [] };

          const updatedPages = oldData.pages.map((page) => ({
            ...page,
            data: page.data.map((c) => {
              if (c.id === comment.replyToId) {
                return {
                  ...c,
                  attributes: {
                    ...c.attributes,
                    replyCount: Math.max(0, (c.attributes.replyCount ?? 0) - 1),
                    replyDeletedCount:
                      (c.attributes.replyDeletedCount ?? 0) + 1,
                  },
                };
              }
              return c;
            }),
          }));

          return {
            pages: updatedPages,
          };
        }
      );
    } else {
      // If the parent comment is a reply, update its parent's replies query
      const parentRepliesKey = getCommentsKey(programId, contentId, {
        parentId: comment.parentComment.replyToId,
      });
      queryClient.setQueryData<{ pages: CommentsCollectionData[] }>(
        parentRepliesKey,
        (oldData) => {
          if (!oldData?.pages) return { pages: [] };

          const updatedPages = oldData.pages.map((page) => ({
            ...page,
            data: page.data.map((c) => {
              if (c.id === comment.replyToId) {
                return {
                  ...c,
                  attributes: {
                    ...c.attributes,
                    replyCount: Math.max(0, (c.attributes.replyCount ?? 0) - 1),
                    replyDeletedCount:
                      (c.attributes.replyDeletedCount ?? 0) + 1,
                  },
                };
              }
              return c;
            }),
          }));

          return {
            pages: updatedPages,
          };
        }
      );
    }
  }
};

export const useDeleteCommentMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: QueryError) => void;
} = {}): MutationResponse<Comment> => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    ['delete_comment'],
    deleteComment,
    {
      onSuccess: (_, variables) => {
        updateCommentCache(variables, queryClient);
        onSuccess?.();
      },
      onError,
    }
  );

  return { mutate, isLoading, errorMessage: error?.message };
};

/**
 * Hook for fetching paginated comments using infinite scroll
 */
export const useComments = ({
  programId,
  contentId,
  filters = [],
  pageSize = 10,
  enabled = true,
  refetchInterval = false,
}: UseCommentsProps): InfiniteQueryResponse<CommentsData> => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery<CommentsCollectionData, Error>(
    getCommentsKey(programId, contentId, { filters }),
    async ({ pageParam = 1 }) => {
      return fetchComments({
        programId,
        contentId,
        filters,
        page: pageParam,
        pageSize,
      });
    },
    {
      enabled,
      refetchInterval,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage < (lastPage.meta.totalPages ?? 0)
          ? lastPage.meta.currentPage + 1
          : undefined,
      keepPreviousData: true,
    }
  );

  return {
    data: data?.pages.flatMap((page) => page.data) ?? [],
    errorMessage: error?.message,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};

/**
 * Hook for fetching paginated replies using infinite scroll
 */
export const useCommentReplies = ({
  programId,
  contentId,
  commentId,
  filters = [],
  pageSize = 10,
  enabled = true,
  refetchInterval = false,
}: UseCommentRepliesProps): InfiniteQueryResponse<CommentsData> => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery<CommentsCollectionData, Error>(
    getCommentsKey(programId, contentId, {
      parentId: commentId,
      filters,
    }),
    async ({ pageParam = 1 }) => {
      return fetchReplies({
        programId,
        contentId,
        commentId,
        page: pageParam,
        pageSize,
      });
    },
    {
      enabled,
      refetchInterval,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage < (lastPage.meta.totalPages ?? 0)
          ? lastPage.meta.currentPage + 1
          : undefined,
      keepPreviousData: true,
    }
  );

  return {
    data: data?.pages.flatMap((page) => page.data) ?? [],
    meta: data?.pages[0]?.meta,
    errorMessage: error?.message,
    isLoading: isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
