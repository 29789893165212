import { RouteComponentProps, Router } from '@reach/router';
import React, { useState } from 'react';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useMergeIntegrations } from 'hooks/merge-integration';
import {
  getIntegrationLabel,
  SupportedIntegration,
} from 'models/MergeIntegration/integrations';
import { ValidationError } from 'services/Errors/ValidationError';
import { WithPermission } from 'shared/WithPermission';
import { ConfigureIntegration } from './ConfigureIntegration';
import { ConfigureUserIdentifiers } from './ConfigureUserIdentifier';
import { MergeIntegrationContext } from './context';
import { ViewUserMapping } from './ViewUserMapping';

type MergeIntegrationProps = RouteComponentProps & {
  type: SupportedIntegration;
};

export const MergeIntegration: React.FC<MergeIntegrationProps> = ({ type }) => {
  const [isUnsupported, setIsUnsupported] = useState(false);

  useMergeIntegrations(type, {
    onSettled: (_, error) => {
      setIsUnsupported(error instanceof ValidationError);
    },
  });

  const integrationLabel = getIntegrationLabel(type);

  return (
    <WithPermission
      permissions={['configureMergeIntegrationsAccess']}
      operation="all"
    >
      {isUnsupported ? (
        <FormPage
          title={integrationLabel}
          breadcrumbs={[
            { to: '..', label: 'Configure' },
            { label: integrationLabel },
          ]}
        >
          <p>
            This integration is not yet supported. Please contact support for
            more information.
          </p>
        </FormPage>
      ) : (
        <MergeIntegrationContext.Provider
          value={{
            type,
          }}
        >
          <Router>
            <ConfigureIntegration path="/" />
            <ConfigureUserIdentifiers path="/:integrationId/user-identifiers" />
            <ViewUserMapping path="/:integrationId/user-mapping/preview" />
          </Router>
        </MergeIntegrationContext.Provider>
      )}
    </WithPermission>
  );
};
